import React, { useEffect, useState } from 'react'

import MainContentBlock from '../../Components/MainContentBlock/MainContentBlock'
import axios from 'axios'
//import Modal from '../../Components/Popup/Modal'

//insert "<Modal></Modal>" in line 19 and uncoment line 5 to enable popup 

const Main = ({ }) => {
    // const [articles, setArticles] = useState([])




    // const data = require('../../data.json')
    //запрос
    return (
        <>
        
        <div className='main-page'>
            <div className="rough-block">
                <MainContentBlock blockName={'rough'} />
            </div>
            <div className='polished-block'>
                <MainContentBlock blockName={'polished'} />
            </div>
        </div></>
    )
}

export default Main